body {
  font-family: Arial, sans-serif;
  text-align: center;
  overflow-x: hidden;
}

h1 {
  margin-top: 50px;
}


textarea {
  padding: 10px;
  
  width: 80%;
  height: 150px;
  margin: 20px auto;
  display: block;
  border-radius: 5px;
  border: 1px solid gray;
  resize: none;

  font-family: Arial, sans-serif;
  font-size: 18px;
}

button {
  width: 30%;
  height: 40px;
  font-size: 20px;
  margin: 20px 0;
  border-radius: 5px;
  border: 1px solid #37C6AC;
  background-color: #37C6AC;
  color: white;
  cursor: pointer;
}

.answer {
  width: 80%;
  height: 200px;
  margin: 20px 0;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid lightgray;
  background-color: white;
  text-align: left;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: pre-wrap;

  font-size: 18px; 
}


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 45vh;
}


.footer {
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

.terms-spacer {
  height: 70px; /* adjust as needed */
}



.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.content-wrap {
  flex: 1;
}
